.AboutContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(40, 41, 52, 0.85); /* 改为稍微提亮的颜色并添加透明度 */
  backdrop-filter: blur(10px); /* 添加磨砂效果 */
  -webkit-backdrop-filter: blur(10px); /* 支持Safari */
}

.ProfilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 3px solid #8fe1b5; /* 突出显示的边框 */
  object-fit: cover;
}

.PersonalInfo {
  text-align: left;
  font-family: 'Roboto', sans-serif; /* 使用现代字体 */
  color: #ecf0f1;
}

.PersonalInfo p {
  margin: 15px 0;
  line-height: 1.6;
}

.PersonalInfo a {
  color: #4a90e2;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.PersonalInfo h2 {
  margin-bottom: 10px;
  font-size: 24px;
  color: #4a90e2;
}

.communications {
  display: flex;
  justify-content: center;
}

.comm_link {
  margin-inline-start: 10px;
}
