.publications {
  overflow: hidden; /* 隐藏溢出部分 */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.publication {
  overflow: hidden; /* 隐藏溢出部分 */
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(40, 41, 52, 0.85); /* 提亮并添加透明度 */
  backdrop-filter: blur(10px); /* 添加磨砂效果 */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  margin: 10px 0;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  transition: transform 0.3s ease; /* 过渡效果 */

  &:hover {
    transform: translateY(-5px); /* 悬停效果 */
  }
}

.publication-image {
  width: 178px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover; /* 确保图片裁剪而不变形 */
}

.publication-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.publication-title {
  font-size: 18px;
  color: #333;
  margin: 0 0 10px 0;

  a {
    text-decoration: none;
    color: #4a90e2;
    transition: color 0.3s ease;

    &:hover {
      color: #3b5998;
    }
  }
}

.publication-authors {
  font-size: 14px;
  color: #666;
  margin: 0 0 5px 0;
}

.publication-info {
  font-size: 14px;
  color: #999;
}
