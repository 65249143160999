.skillset {
  background: #1e1f2a; // Dark background with a hint of purple
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  height: 80%; // Set the height to 80%
  overflow-y: auto; // Add vertical scroll if content overflows

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #00ade1; // Light blue color for the heading
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 10px; // Reduce padding to decrease row spacing
      text-align: left;
      border-bottom: 1px solid #333;
    }

    th {
      color: #a78bfa; // Light purple color for the table headers
      font-size: 1.2em;
    }

    td {
      strong {
        color: #ff6493; // Pink color for the domain text
      }

      a {
        display: inline-block;
        margin: 5px;
        transition: transform 0.2s, box-shadow 0.2s;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
