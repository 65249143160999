.Details {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-width: 300px;
  /* transition: all 0.5s ease; */
}

.DetailsContent {
  width: 100%;
  color: white;
  text-align: left;
}
