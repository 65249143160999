@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.Main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: left;
  transition: all 0.5s ease;
}
.MainContent {
  width: 100%;
  max-width: 500px;
}
.show-details .Main {
  width: 50%;
  transition: all 0.5s ease;
}
.header {
  margin-bottom: 50px;
}
.header h1 {
  font-size: 3em;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.header h2 {
  font-size: 1.5em;
  color: #ffffff60;
  letter-spacing: 0.05em;
  margin-top: 10px;
  text-transform: uppercase;
}
ul {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
ul li {
  list-style: none;
  position: relative;
}
ul li a {
  position: relative;
  font-size: 3em;
  text-decoration: none;
  color: #fff2;
  letter-spacing: 0.25em;
  line-height: 1em;
  text-transform: capitalize;
}
ul li a::before {
  content: attr(data-text);
  position: absolute;
  color: var(--clr);
  width: 0;
  overflow: hidden;
  border-right: 6px solid var(--clr);
  transition: 1s;
}
ul li a:hover::before {
  width: 100%;
  filter: drop-shadow(0 0 25px var(--clr));
}
ul li a span {
  position: relative;
  animation: animateText linear infinite;
}
ul li a:hover span {
  opacity: 0.15;
}

@keyframes animateText {
  0%,
  20% {
    color: #fff2;
    filter: drop-shadow(0 0 0 var(--clr));
  }
  21.001%,
  79.999% {
    color: var(--clr);
    filter: drop-shadow(0 0 25px var(--clr));
  }
  80%,
  100% {
    color: #fff2;
    filter: drop-shadow(0 0 0 var(--clr));
  }
}
