.App {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #1e1f2a;
  justify-content: center;
  align-items: center;
}
body {
  overflow: hidden;
}

.show-details {
  justify-content: flex-start;
}

.main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 850px;
}
